const gr = {
    buttons: {
        login: "Σύνδεση",
        register: "Εγγραφή",
        search: "Αναζήτηση",
        add_blacklist: "Προσθήκη στη μαύρη λίστα",
        accept_all: "Αποδοχή όλων"
    },
    dropdown: {
        set_admin_role: "Ρόλος Διαχειριστή",
        set_user_role: "Ρόλος Χρήστη",
        set_status_accepted: "Αποδοχή",
        set_status_rejected: "Απορριψη",
        set_status_pending: "Εκκρεμής",
        set_deleted: "Διαγραφή"
    },
    account: {
        address: "Διεύθυνση",
        address2: "Κτίριο / Διαμέρισμα",
        municipality: "Δήμος / Κοινότητα",
        zip_code: "Ταχ. Κώδικας"
    },
    titles: {
        total_payments: "Σύνολο Πληρωμών",
        total_apps: "Σύνολο Αιτήσεων",
        no_parcel_found:"Δεν βρέθηκαν αποτελέσματα",
        new: "Νέα καταχώρηση",
        export: "Εξαγωγή",
        company_id: "Αριθμός Μητρώου Εταιρίας",
        dashboard: "Πίνακας Ελέγχου",
        applications: "Οι Αιτήσεις μου",
        account: "Ο Λογαριασμός μου",
        admin: "Διαχείριση",
        enquiries: "Αιτήσεις",
        users: "Χρήστες",
        statistics: "Στατιστικά",
        app_map: "Χάρτης Αιτήσεων",
        area_map: 'Χάρτης Περιοχής',
        admin_map: 'Χάρτης Διαχείρισης',
        settings: "Ρυθμίσεις",
        logout: "Αποσύνδεση",
        menu: "Μενού",
        contact: "Επικοινωνία",
        home: "Αρχική Σελίδα",
        disclaimer: "Αποποίηση ευθυνών",
        address: "Διεύθυνση",
        telephone: "Τηλέφωνο",
        copyright: "Υπουργείο Γεωργίας, Αγροτικής Ανάπτυξης και Περιβάλλοντος - Όλα τα δικαιώματα επιφυλάσσονται",
        powered_by: "Υποστηρίζεται από Geo-Grafica Ltd",
        name: "Όνομα",
        national_id: "Αρ. Ταυτότητας",
        email: "Ηλεκτρ. Ταχυδρομείο",
        phone_number: "Αρ. Τηλεφώνου",
        iban_number: "Αρ. IBAN",
        type: "Τύπος Προφίλ",
        role: "Ρόλος",
        reg_date: "Ημ. Δημιουργία",
        user_profile: "Προφίλ Χρήστη",
        user_profile_update: "Ανανέωση Προφίλ",
        user_profile_update_success:"Το προφίλ ενημερώθηκε με επιτυχία",
        select_year: "Επιλέξτε Έτος",
        all: "Ολα",
        total_users: "Σύνολο Χρηστών",
        apps_by_village: "Αιτήσεις ανά χωριό",
        ownerships_by_village: "Ιδιοκτησίες ανά χωριό",
        total_paid_by_village: "Σύνολο πληρωμών ανά χωριό",
        village_code: "Χωριό",
        total: "Σύνολο",
        application_id: "Αρ. Αίτησης",
        id: "Α/Α",
        unique_identifier: "Διακριτικός Αριθμός",
        actions: "Ενέργειες",
        iban_error: "Mη έγκυρος αριθμός IBAN",
        download_csv: "Λήψη Αρχείου CSV",
        payments: "Πληρωμές",
        no_payments: "Δεν υπάρχουν πληρωμές",
        blacklist: "Μαύρη λίστα",
        help: "Οδηγίες Χρήσης",
    },
    home: {
        title: "ΤΟΠΙΚΟ ΣΧΕΔΙΟ ΑΚΑΜΑ - ΕΠΙΔΟΤΗΣΗ ΑΚΙΝΗΤΗΣ ΙΔΙΟΚΤΗΣΙΑΣ",
        title_2: "Υλοποίηση Πλαισίου Μέτρων και Δράσεων Ενίσχυσης της Αναπτυξιακής Δραστηριότητας των Κοινοτήτων Ακάμα.",
        description: "Παραχώρηση επιδότησης στους ιδιοκτήτες γης με βάση την έκταση, λόγω της συμβολής των τεμαχίων της υπαίθρου στην προστασία του τοπίου της περιοχής, καθώς και στη διατήρηση της βιοποικιλότητας της.",
        description_2: "Σκοπός του μέτρου είναι η οικονομική ενίσχυση και στήριξη των ιδιοκτητών γης (Νομικά ή φυσικά πρόσωπα) εντός των ορίων του Τοπικού Σχεδίου Ακάμα, λόγω της συμβολής των τεμαχίων γης στην προστασία του τοπίου, καθώς και στη διατήρηση της βιοποικιλότητας της περιοχής, η οποία είναι μοναδική σε εθνικό επίπεδο.",
        description_3: "Το μέτρο θα υλοποιηθεί μέσω εθνικών πόρων, θα ισχύει για μία πενταετία και θα επανεξεταστεί μετά το πέρας της πενταετίας, βάσει της απόδοσης ωφελειών από τα άλλα μέτρα κοινωνικοοικονομικής ενίσχυσης της περιοχής.",
        description_4: "Υλοποίηση Μέτρου Αρ. 1",
        description_5: "Απαιτείται Εγγραφή στην υπηρεσία CY Login",
        description_6: "Η ενίσχυση προτείνεται να παραχωρείται σε φυσικά και νομικά πρόσωπα, τα οποία κατόπιν υποβολής αίτησης στον Φορέα εφαρμογής του μέτρου και τεκμηρίωσης της ιδιοκτησιακής τους ιδιότητας, θα λαμβάνουν ετησίως ποσό ανάλογο με την αξία ενοικίων γεωργικής γης στην περιοχή και της περιβαλλοντικής συνεισφοράς της ιδιοκτησίας.",
        description_7: "Οι υποχρεώσεις των ιδιοκτητών βάσει του σχεδίου, θα είναι να διατηρήσουν την περιουσία τους στην υφιστάμενή της κατάσταση. Συνεπώς, εάν η ιδιοκτησία παρουσιάζει γεωργική δραστηριότητα αυτή να συνεχίσει, ενώ εάν παραμένει σε αδράνεια να μην υπάρξει διατάραξη του τοπίου και της φυσικής βλάστησης που έχει αναπτυχθεί, αλλά διαχείριση της βλάστησης αυτής, έτσι ώστε να προάγεται η προστασία των οικοτόπων, ειδών και οικοσυστημικών υπηρεσιών. Πρόσθετα θα υποχρεούται να μην την περιφράξει και να μην παρεμβεί σε αυτή με οποιοδήποτε τρόπο. Παρόλα αυτά, θα δίνεται η ευελιξία αλλαγής δραστηριότητας εντός του τεμαχίου γης (π.χ. από εγκαταλελειμμένη γεωργική γη σε αξιοποιήσιμη), βάσει έγκρισης σχετικής αίτησης από κρατική Επιτροπή Αξιολόγησης Αιτήσεων.",
        description_8: "Καθορίζεται ως οροφή ενίσχυσης ανά δικαιούχο €6.600 ετησίως.",
        instructions: "Για οδηγίες χρήσης της εφαρμογής παρακαλώ πατήστε",
    },
    dashboard: {
        account: "Για να ενημερώσετε το Προφίλ σας παρακαλώ πατήστε",
        application: "Για να υποβάλετε την Αίτηση σας παρακαλώ πατήστε",
        here: "εδώ"
    },
    applications: {
        try_again: "Υπάρχουν τεχνικά προβλήματα, δοκιμάστε ξανά αργότερα",
        owner: "Ιδιοκτήτης",
        no_apps: "Δεν υπάρχουν αιτήσεις",
        no_ownerships: "Δεν βρέθηκαν ιδιοκτησίες",
        loading: "Φορτωση αιτήσεων",
        new_application: "ΝΕΑ ΑΙΤΗΣΗ",
        id: "Α/Α",
        external_id: "Αριθμός Αίτησης",
        year: "Έτος",
        status: "Κατάσταση",
        application_date: "Ημερ. Υποβολής",
        total_area: "Εκταση",
        subsidy: "Επιχορήγηση",
        general_info: "Γενικές Πληροφορίες",
        app_id: "Αριθμός Αίτησης",
        ownerships: "Ιδιοκτησίες",
        location: "Τοποθεσία",
        subprop_id: "Υπό-Ιδιοκτησία",
        district: "Επαρχία",
        community: "Δήμος/Κοινότητα",
        quarter: "Ενορία",
        sheet: "Φύλλο",
        plan: "Σχέδιο",
        block: "Τμήμα",
        parcel_no: "Αρ. Τεμαχίου",
        scale: "Κλίμακα",
        registration: "Αρ. Εγγραφής",
        locality: "Τοποθεσία",
        extent: "Έκταση",
        share: "Μερίδιο",
        per_page: "Ανά Σελίδα",
        total_extent: "Ολική Έκταση",
        total_subsidy: "Ολική Επιδότηση",
        property_disclaimer: "Αν οποιοδήποτε από τα ακίνητά σας δεν εμφανίζεται στη λίστα, παρακαλώ απευθυνθείτε σε ένα Επαρχιακό Κτηματολογικό Γραφείο ή χρησιμοποιήστε την ή-αίτηση για να δηλώσετε το ακίνητό σας.",
        property_disclaimer2: "Το θέμα θα ερευνηθεί από αρμόδιο Λειτουργό και θα ενημερωθείτε σχετικά.",
        property_disclaimer_url: "https://eforms.eservices.cyprus.gov.cy/MOI/LS/DeclarationSubmit?_lang=el",
        property_disclaimer_url_text: "Δήλωση Ακινήτου (Μέσω Συστήματος GOV.CY)", 
        agree_terms: "Συμφωνώ να μην αλλάξω τη χρήση γης, να μην τοποθετήσω φράχτη ή να παρέμβω με οποιονδήποτε τρόπο στις ιδιοκτησίες που αναφέρονται στην τρέχουσα αίτηση για περίοδο ενός (1) χρόνου.",   
    },
    dialogs: {
        confirm_title: "Υποβολή",
        confirm_body: "Είστε βέβαιος ότι θέλετε να υποβάλετε την αίτησή σας;",
        confirm_no: "Όχι",
        confirm_yes: "Ναι",
        confirm_submit: "Υποβολή Αίτησης",
        profile_title: "Υποβολή",
        profile_body: "Είστε βέβαιος ότι θέλετε να ενημερώσετε το προφίλ σας;",
        profile_no: "Όχι",
        profile_yea: "Ναι",
        profile_submit: "Ενημέρωση Προφίλ",
        inform_title: "Πληροφορία",
        inform_ok: "Εντάξει",
        inform_iban_body: "Πρέπει να καθορίσετε αριθμό IBAN",
        please_wait: "Παρακαλώ Περιμένετε...",
        gathering_ownerships: "Ανάκτηση Ιδιοκτησιών..."
    },
    urls: {
        home_url: "https://www.gov.cy/moa/",
        contact_url: "https://www.gov.cy/moa/contact/",
        disclaimer_url: "https://www.gov.cy/moa/politiki-aporritoy/",
        user_manual_url: "https://support-akamas.com/Akamas_User_Manual_gr.pdf",
    },
    settings: {
        ownerships_title: "Ημερομηνία Ιδιοκτησιών",
        ownerships_date: "Παρακαλώ επιλέξτε Ημερομηνία Ιδιοκτησιών",
        ownerships_save: "Φύλαξη",
        activation_title: "Ενεργοποίηση Συστήματος",
        activate_button: "Ενεργοποίηση",
        activate_desc: "Το Σύστημα είναι Ενεργό και δέχεται Αιτήσεις",
        deactivate_desc: "Το Σύστημα είναι Ανενεργό",
        deactivate_button: "Απενεργοποίηση",
    }
  };
  
export default gr