const en = {
    buttons: {
        login: "Login",
        register: "Register",
        search: "Search",
        add_blacklist: "Add blacklist",
        accept_all: "Accept all"
    },
    dropdown: {
        set_admin_role: "Set Admin role",
        set_user_role: "Set User role",
        set_status_accepted: "Accept",
        set_status_rejected: "Reject",
        set_status_pending: "Pending",
        set_deleted: "Delete"
    },
    account: {
        address: "Address",
        address2: "Building Name / Flat",
        municipality: "Municipality / Community",
        zip_code: "Zip Code"
    },
    titles: {
        total_payments: "Total payments",
        total_apps: "Total applications",
        no_parcel_found:"No results found",
        new: "New",
        export: "Export",
        company_id: "Company No",
        dashboard: "Dashboard",
        applications: "My Applications",
        account: "My Account",
        admin: "Admin",
        enquiries: "Applications",
        users: "Users",
        statistics: "Statistics",
        app_map: "App Map",
        area_map: 'Area Map',
        admin_map: 'Admin Map',
        settings: "Settings",
        logout: "Logout",
        menu: "Menu",
        contact: "Contact",
        home: "Home",
        disclaimer: "Disclaimer",
        address: "Address",
        telephone: "Telephone",
        copyright: "Ministry of Agriculture, Rural Development and Environment - All rights reserved.",
        powered_by: "Powered by Geo-Grafica Ltd", 
        name:"Name",
        national_id: "National ID",
        email: "Email",
        phone_number: "Phone Number",
        iban_number: "IBAN Number",
        type: "Profile Type",
        role: "Role",
        reg_date: "Create Date",
        user_profile: "User Profile",
        user_profile_update: "Update Profile",
        user_profile_update_success:"Profile was successfully updated",
        select_year: "Select Year",
        all: "All",
        total_users: "Total users",
        apps_by_village: "Applications by village",
        ownerships_by_village: "Ownerships by village",
        total_paid_by_village: "Total paid by village",
        village_code: "Village",
        total: "Total",
        application_id: "Application ID",
        id: "A/A",
        unique_identifier: "Unique Identifier",
        actions: "Actions",
        iban_error: "Invalid IBAN",
        download_csv: "Download CSV",
        payments: "Payments",
        no_payments: "There are no payments",
        blacklist: "Blacklist",
        help: "Instructions",
    },
    home: {
        title: "AKAMAS LOCAL PLAN - LAND OWNERSHIP SUBSITY",
        title_2: "Implementation of the Framework of Measures and Actions to Support the Developmental Activity of the Akamas Communities.",
        description: "Providing financial support to landowners based on the size of their land, recognizing how their rural properties help protect the local landscape and preserve biodiversity in the area.",
        description_2: "The purpose of this measure is to provide financial support to landowners (both legal entities and individuals) within the boundaries of the Akama Local Plan. This support is due to the contribution of their land parcels to the protection of the landscape and the preservation of the area's biodiversity, which is unique at the national level.",
        description_3: "It will be funded by national resources, in effect for five years, and reviewed afterward based on the results of other social and economic initiatives in the area.",
        description_4: "Implementation of Measure No. 1",
        description_5: "Registration to CY Login is required",
        description_6: "The proposed support would be available to both individuals and companies. After applying to the responsible authority and proving their ownership, they would receive an annual payment based on the rental value of agricultural land in the region and the environmental benefits their property provides.",
        description_7: "The obligations of the landowners under the plan will be to maintain their property in its current condition. Therefore, if the property is being used for agricultural activities, they must continue, while if it remains inactive, the landscape and natural vegetation that has developed should not be disturbed but managed in a way that promotes the protection of habitats, species, and ecosystem services. Additionally, they will be required not to fence it or intervene in any way. However, flexibility will be provided to change the activity within the land parcel (e.g., from abandoned agricultural land to usable), based on the approval of a relevant application by a Application Evaluation Committee.",
        description_8: "The maximum support each beneficiary can receive is capped at €6,600 per year.",
        instructions: "For instructions on how to use the application please click",
    },
    dashboard: {
        account: "To update your Profile please click",
        application: "To submit your Application please click",
        here: "here"
    },
    applications: {
        try_again: "There are technical issues, please try again later",
        owner: "Owner",
        no_apps: "There are no applications",
        no_ownerships: "There are no ownerships",
        loading: "Loading applications",
        new_application: "NEW APPLICATION",
        id: "ID",
        external_id: "External ID",
        year: "Year",
        status: "Status",
        application_date: "Date Submitted",
        total_area: "Total Area",
        subsidy: "Subsidy",
        general_info: "General Information",
        app_id: "Application ID",
        ownerships: "Ownerships",
        location: "Location",
        subprop_id: "Subprop ID",
        district: "District",
        community: "Municipality/Community",
        quarter: "Quarter",
        sheet: "Sheet",
        plan: "Plan",
        block: "Block",
        parcel_no: "Parcel No",
        scale: "Scale",
        registration: "Registration",
        locality: "Locality",
        extent: "Extent",
        share: "Share",
        per_page: "Per Page",
        total_extent: "Total Extent",
        total_subsidy: "Total Subsidy",
        property_disclaimer: "If any of your properties is not shown on the «Property Catalogue» above, please apply to a District Land Office or declare your property using the e-service ",
        property_disclaimer2: "Your request will be investigated and you will be informed accordingly.",
        property_disclaimer_url: "https://eforms.eservices.cyprus.gov.cy/MOI/LS/DeclarationSubmit?_lang=en",
        property_disclaimer_url_text: "Property Declaration (Via GOV.CY System).",
        agree_terms: "Agree not to change the current land use, not to fence or interference in any way with the properties listed in the current application for a period of one (1) year.",
    },
    dialogs: {
        confirm_title: "Submit",
        confirm_body: "Are you sure you want to submit your application?",
        confirm_no: "No",
        confirm_yes: "Yes",
        confirm_submit: "Submit Application",
        profile_title: "Submit",
        profile_body: "Are you sure you want to update your profile",
        profile_no: "No",
        profile_yea: "Yes",
        profile_submit: "Update Profile",
        inform_title: "Information",
        inform_ok: "OK",
        inform_iban_body: "IBAN Number is required",
        please_wait: "Please Wait...",
        gathering_ownerships: "Gathering Ownerships..."
    },
    urls: {
        home_url: "https://www.gov.cy/moa/en",
        contact_url: "https://www.gov.cy/moa/en/contact/",
        disclaimer_url: "https://www.gov.cy/moa/en/privacy-statement/",
        user_manual_url: "https://support-akamas.com/Akamas_User_Manual_en.pdf",
    },
    settings: {
        ownerships_title: "System Ownerships",
        ownerships_date: "Please select ownerships date",
        ownerships_save: "Save",
        activation_title: "System Activation",
        activate_button: "Activate",
        activate_desc: "The System is Active and accepting Applications",
        deactivate_desc: "The System is Not Active",
        deactivate_button: "Deactivate",
    }
  };
  
export default en